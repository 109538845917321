.list-headline {
  margin-bottom: 64px;
}
.list-headline__ttl {
  text-align: center;
  fill: var(--theme-primary-color);
}
.list-headline__ic {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.list-headline__ic i {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  flex-shrink: 0;
}
.list-headline__ic .primary {
  background: var(--theme-primary-color);
}
.list-headline__ic .secondary {
  background: var(--theme-secondary-color);
}
.list-headline__ic .primary.top {
  top: -40px;
  right: calc(50% - 280px);
  position: absolute;
  margin-left: 0;
}
.list-headline__ic .secondary.top {
  top: -40px;
  left: calc(50% - 280px);
  position: absolute;
  margin-left: 0;
}
.list-headline__ic i:not(:first-child) { margin-left: 64px; }
.list-headline__ic i:nth-child(2) { margin-top: 24px; }
.list-headline__ic i:nth-child(4) { margin-top: 16px; }
.list-headline__ic i:nth-child(5) { margin-top: 32px; }
.list-headline__ic i:nth-child(8) { margin-top: 32px; }
.list-headline__ic i:nth-child(9) { margin-top: 16px; }
.list-headline__ic i:nth-child(11) { margin-top: 24px; }
@media screen and (max-width:768px) {
  .list-headline {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .list-headline__ic {
    width: 100%;
    overflow: hidden;
  }
}